import React, { Component } from "react";
import { Card, Carousel, Col, Container } from "react-bootstrap";
import person from "../../images/quotes.png";
class C7 extends Component {
  render() {
    if (!this.props.data) return <></>;
    const userReviews = this.props.data;
    let reviewCarousel = [];
    userReviews.content.map((_, index) => {
      if (index % 2 == 0) {
        reviewCarousel.push(userReviews.content.slice(index, index + 2));
      }
    });
    return (
      <Container fluid id="testimonials" className="c7 text-center c-pad">
        <div className="c-h1 c7-h1">{this.props.data.header}</div>
        <div className="row mx-auto">
          <Col className="d-md-none">
            <Carousel>
              {this.props.data.content.map((review, index) => (
                <Carousel.Item className="" key={index}>
                  <div className="carousel2 d-flex justify-content-center">
                    <div>
                      <Card className="text-center m-3 p-3 c-shadow">
                        <img
                          alt=""
                          className="mx-auto"
                          src={person}
                          height="auto"
                          width="40px"
                        />
                        <div className="c-h2 carousel2-h1">
                          {review.comment}
                        </div>
                        <div className="c6-green"> {review.name}</div>
                        <div className="c2-h2"> {review.footer}</div>
                      </Card>
                    </div>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
          <Col className="d-none d-md-block">
            <Carousel>
              {reviewCarousel.map((review, index) => {
                return (
                  <Carousel.Item className="" key={index}>
                    <div className="carousel2 d-flex justify-content-center">
                      {Object.keys(review).map((item) => {
                        return (
                          <Col className="col-6" key={item}>
                            <Card className="text-center  mx-3 p-3 c-shadow">
                              <img
                                alt=""
                                className="mx-auto"
                                src={person}
                                height="auto"
                                width="40px"
                              />
                              <div className="c-h2 carousel2-h1">
                                {review[parseInt(item)].comment}
                              </div>
                              <div className="c6-green mt-auto">
                                {review[parseInt(item)].name}
                              </div>
                              <div className="c2-h2">
                                {review[parseInt(item)].footer}
                              </div>
                            </Card>
                          </Col>
                        );
                      })}
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </Col>
        </div>
      </Container>
    );
  }
}

export default C7;
